<template>
  <ERow class="">
    <PaActiveUsers class="w-100" />
  </ERow>
</template>

<script>
import PaActiveUsers from "@/components/pa/PaActiveUsers"
export default {
  components: {
    PaActiveUsers,
  },
}
</script>
