<template>
  <v-card outlined>
    <div class="d-flex align-center ml-5">
      <v-icon class="grey--text text--darken-1 stats-icon pr-2 mb-1">
        fa-users
      </v-icon>
      <div class="subtitle-1 grey--text text--darken-1 font-weight-medium mr-3">
        Active Users Chart
      </div>
    </div>
    <v-divider />
    <ECol cols="12">
      <v-menu
        v-model="dateRangePicker"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on, attrs }">
          <ECol cols="12" md="2" sm="6" class="pl-2 pb-0">
            <v-text-field
              v-model="displayDateRange"
              placeholder="From - To"
              readonly
              dense
              hide-details
              v-bind="attrs"
              label="From/To Date"
              outlined
              v-on="on"
            />
          </ECol>
        </template>
        <v-date-picker
          v-model="dateRange"
          range
          type="month"
          @change="dateRangePicker = false"
        />
      </v-menu>
      <div v-if="loading" class="d-flex justify-center align-center mt-10">
        <v-progress-circular
          indeterminate
          :size="80"
          :width="5"
          color="primary"
        />
      </div>
      <PaChart
        v-else
        style="height: 60vh"
        :chartData="chartData"
        :initialChartOptions="initialChartOptions"
      />
    </ECol>
  </v-card>
</template>

<script>
import PaChart from "@evercam/shared/components/PaChart"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
export default {
  components: {
    PaChart,
  },
  props: {
    companyExid: {
      type: [String, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      datasets: [],
      labels: [],
      highchartsLabels: [],
      dateRangePicker: false,
      displayDateRange: "",
      dateRange: [],
      companyId: this.$route.params.id,
      from: this.$moment().subtract("12", "months").format("YYYY-MM-DD"),
      to: this.formatDate(new Date(), "YYYY-MM-DD"),
      initialChartOptions: {
        chart: {
          type: "line",
          zoomType: "xy",
        },
        title: {
          text: "Number of active users",
        },
        xAxis: {
          crosshair: true,
          tickInterval: 25,
        },
        yAxis: {
          title: {
            text: "Active Users",
          },
          endOnTick: false,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
          line: {
            linecap: "square",
            lineWidth: 3,
            marker: {
              enabled: false,
            },
          },
        },
      },
    }
  },
  computed: {
    chartData() {
      return {
        datasets: this.datasets,
        highchartsLabels: this.highchartsLabels,
      }
    },
  },
  watch: {
    async dateRange(value) {
      if (value.length === 2) {
        if (this.$moment(`${value[0]}`).isAfter(this.$moment(`${value[1]}`))) {
          this.displayDateRange = value.reverse().join(" / ")
          this.from = `${value[1]}-01`
          this.to = `${value[0]}-${this.$moment(
            value[0],
            "YYYY-MM"
          ).daysInMonth()}`

          return
        }
        this.displayDateRange = value.join(" / ")
        this.from = `${value[0]}-01`
        this.to = `${value[1]}-${this.$moment(
          value[1],
          "YYYY-MM"
        ).daysInMonth()}`
      }
      await this.fetchActiveUsers()
    },
  },
  async mounted() {
    this.dateRange = [
      this.formatDate(this.from, "YYYY-MM"),
      this.formatDate(this.to, "YYYY-MM"),
    ]
  },
  methods: {
    async fetchActiveUsers() {
      try {
        this.loading = true
        this.datasets = []
        this.labels = []
        this.highchartsLabels = []
        let params = {
          from: this.from,
          to: this.to,
          companyId: this.companyExid,
        }
        let response = await EvercamApi.analytics.getActiveUsers({ params })

        let dau = []
        let wau = []
        let mau = []

        response?.forEach((row) => {
          this.labels = [row.date, ...this.labels]
          dau = [row.dau, ...dau]
          wau = [row.wau, ...wau]
          mau = [row.mau, ...mau]
        })

        // Format the highcharts labels to MMM DD
        this.highchartsLabels = this.labels.map((label) => {
          return this.$moment(label).format("MMM DD")
        })

        // Flip the labels in the array
        this.highchartsLabels = this.highchartsLabels.reverse()

        // Highcharts datasets.data have to be [dau[i], label[i]] format
        this.datasets = [
          ...this.datasets,
          {
            label: "Daily Active Users",
            data: dau,
            borderColor: "#3B82F6",
          },
          {
            label: "Weekly Active Users",
            data: wau,
            borderColor: "#028241",
          },
          {
            label: "Monthly Active Users",
            data: mau,
            borderColor: "#f71b02",
          },
        ]
      } catch (error) {
        this.$notifications.error({
          text: "Failed to load active users!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
